@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

* {
  font-family: 'Poppins';
}

:root {
  --black: #000000;
  --white: #ffffff;
  --red: #db4444;
  --lowwhite: #f5f5f5;
}

.header {
  height: 48px;
  background: var(--black);
  color: var(--white);
}

/* footer  */
.footer {
  height: auto;
  /* width: auto; */
  background-color: var(--black);
  color: var(--white);
}
.footer-row {
  padding-top: 80px;
}

.footer-img {
  width: 35px;
  height: 35px;
  cursor: pointer;
}
.footer-img-arrow {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

@media (max-width: 768px) {
  /* .footer {
    height: 800px;
  
  } */
  .footer h6,
  .footer p {
    font-size: smaller; /* Adjust font size */
  }
  .footer .footer-img {
    width: 20px; /* Adjust icon size */
    height: 20px;
  }
}

/* navbar  */

.nav-heart {
  width: 34px;
  height: 34px;
  cursor: pointer;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-left: 10px;
}

.btn-notify {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: var(--white);
  border: none;
  position: relative;
  margin: 2px;
}
.btn-notify:hover {
  background-color: var(--white);
  border: none;
}
.btn-notify:click {
  background-color: var(--white);
  border: none;
}

/* Account  */
.custom-form {
  width: 750px;
  height: 500px;
  border-radius: 4px;
  background-color: var(--white);
  box-shadow: 0px 1px 13px 0px #0000000d;
}

/* For tablets */
@media (max-width: 768px) {
  .custom-form {
    width: 80%;
    height: auto;
    top: 10%;
    left: 10%;
    position: relative;
  }
}

/* For mobile phones */
@media (max-width: 480px) {
  .custom-form {
    width: 95%;
    top: 5%;
    left: 2.5%;
    position: relative;
  }
}

.custom-style {
  width: 214px;
  height: 56px;
  border-radius: 4px;
  background-color: var(--red);
  border: none;
  color: var(--white);
}

/* contact  */

.contact-style {
  height: 457px;
  background-color: var(--white);
  box-shadow: 0px 1px 13px 0px #0000000d;
}

.my-custom-container .g,
.b {
  font-size: 20px;
  font-weight: bold;
}

.icon-contact {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--red);
  color: var(--lowwhite);
}
.contact-form-style {
  background-color: var(--white);
  box-shadow: 0px 1px 13px 0px #0000000d;
}

/* Cards */
.custom-box {
  width: 270px;
  height: 350px;
}

.card-inner {
  width: 270px;
  height: 250px;
  position: relative;
  border-radius: 4px;
  background-color: var(--white);
}
.custom-box:hover .add-cart {
  visibility: visible;
  cursor: pointer;
}

.card-box-inner {
  width: 55px;
  height: 26px;
  border-radius: 4px;
  background-color: var(--red);
  padding: 4px 12px 10px 15px;
  position: absolute;
  top: 4%;
  left: 4%;
}

.card-outer {
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: 1;
}

.pic {
  position: absolute;
  max-height: 100%;
  max-width: 268px;
}

.card-heart {
  position: absolute;
  top: 4%;
  left: 85%;
  z-index: 2;
}

/* wishlist cart  */

.add-cart {
  height: 41px;
  width: 268px;
  background-color: var(--black);
  position: absolute;
  bottom: 0%;
  left: 5%;
  z-index: 2;
  visibility: hidden;
}

.wishlist-style {
  width: 159px;
  height: 56px;
  border-radius: 4px;
  border: 2px solid --black;
  background-color: var(--lowwhite);
}
/* category  */
/* .cate-item {
  width: 170px;
  height: 145px;
  border-radius: 4px;
  border: 1px;
} */

.cate-item {
  width: 170px;
  height: 145px;
  border-radius: 4px;
  border: 1px;
  margin: 0 auto;
  transition: background-color 0.3s; /* Centers the item in the Bootstrap column */
}
.cate-item:hover {
  background-color: var(--red);
  color: var(--white);
}

/* Home */

.red-box {
  width: 20px;
  height: 40px;
  border-radius: 4px;
  background-color: var(--red);
  color: var(--red);
}

.btn-product {
  width: 234px;
  height: 56px;
  border-radius: 4px;
  background-color: var(--red);
  color: var(--lowwhite);
  border: none;
}

.text-style {
  font-family: 'poppins';
  font-weight: 400;
  font-size: 36px;
}
.product-style {
  width: 159px;
  height: 56px;
  border-radius: 4px;
  border: none;
  color: var(--lowwhite);
  background-color: var(--red);
}
.black-box {
  width: 100%;
  background-color: var(--black);
  position: relative;
}

.text-color {
  color: var(--white);
  font-size: 48px;
}

@media (max-width: 768px) {
  .text-color {
    font-size: 36px; /* Smaller font size for smaller screens */
  }

  .timecount-align {
    position: relative;
    top: auto;
    left: auto;
    text-align: center;
  }
}

.buy-now {
  width: 171px;
  height: 56px;
  border-radius: 4px;
  border: none;
  color: var(--lowwhite);
  background-color: #00ff66;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
  .responsive-img {
    width: 100%; /* Adjust as needed */
    height: auto;
  }
}

/* heart and eye  */
.icon-heart {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  color: #000000;
  background-color: var(--white);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-heart:hover {
  background-color: var(--red);
  color: var(--lowwhite);
}

/* arrowkeys style  */
.arrow-keys {
  cursor: pointer;
  border-radius: 50%;
  background-color: var(--white);
}
.arrow-keys:hover {
  background-color: var(--red);
  color: var(--lowwhite);
}

/* new arrival */
.box-play {
  border-radius: 4px;
  margin: 2px;
}

.box-play-0,
.box-play-1,
.box-play-2 {
  background-color: var(--black);
  position: relative;
}

.image-text,
.image-text-0 {
  position: absolute;
  bottom: 7%;
  left: 9%;
  color: #f5f5f5;
}

/* Adjustments for medium screens */
@media (max-width: 768px) {
  .box-play {
    height: auto; /* Adjust height for smaller screens */
  }

  .box-play-1,
  .box-play-2 {
    height: auto;
  }

  .image-text,
  .image-text-0 {
    bottom: 10%; /* Adjust text position for smaller screens */
    left: 5%;
  }
}

/* Further adjustments for extra small screens */
@media (max-width: 576px) {
  .image-text,
  .image-text-0 {
    bottom: 15%;
    left: 10%;
  }
}

.play-position {
  position: absolute;
  color: #f5f5f5;
}

.a {
  color: var(--lowwhite);
}

/* about */

.about-style {
  width: 270px;
  height: 230px;
  border-radius: 4px;
}
.about-style:hover {
  background-color: var(--red);
  color: var(--lowwhite);
}

/* product details  */
.detail-product-small {
  width: 75%;
  height: 75%;
  display: flex;
  justify-content: center;
  /* align-items:; */
  background-color: var(--white);
  border-radius: 4px;
  border-color: black;
  margin: auto;
  margin-top: 3px;
}
.detail-product-large {
  width: 500px;
  height: 570px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  border-radius: 4px;
  border-color: #000000;
  margin: auto;
  margin-top: 3px;
}

.color-button-0 {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #a0bce0;
}
.color-button-1 {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #e07575;
}
.color-button-0,
.color-button-1:hover {
  border: 3px solid #000000;
}
.product-size {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background-color: var(--lowwhite);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: Poppins;
  margin-left: 4px;
}

.product-size:hover {
  background-color: #db4444;
  color: #ffffff;
}
.product-buynow {
  width: 165px;
  height: 44px;
  border-radius: 4px;
  background-color: var(--red);
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: Poppins;
  margin-left: 4px;
}
.product-heart {
  width: 40px;
  height: 44px;
  border-radius: 4px;
  border: 1px solid #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
}
.product-heart:hover {
  background-color: var(--red);
  color: var(--white);
  border: none;
}

.product-table {
  width: 400px;
  height: 200px;
  border: 2px solid #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* cart  */
.cart-row {
  border-radius: 4px;
  background-color: var(--white);
  box-shadow: 0px 1px 13px 0px #0000000d;
  margin-bottom: 20px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image-cart {
  width: 54px;
  height: 54px;
  padding: 8px;
}
.cart-style {
  width: 211px;
  height: 56px;
  border-radius: 4px;
  background-color: var(--red);
  border: none;
  color: var(--white);
}
.process-box {
  width: 470px;
  height: 350px;
  border-radius: 4px;
  border: 2px solid #000000;
  padding: 15px;
}
.process-box-row {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}

/* checkout  */
.checkout-input {
  margin-bottom: 20px;
}

#flexCheckDefault {
  width: 25px; /* Width of the checkbox */
  height: 25px; /* Height of the checkbox */
  cursor: pointer;
}

#flexRadioDefault {
  width: 25px; /* Width of the checkbox */
  height: 25px; /* Height of the checkbox */
  cursor: pointer;
}
.image-visa {
  width: 42px;
  height: 28px;
}
.padding-style {
  padding-top: 95px;
}

/* Small screens */
.item-c {
  width: 100%;
}

/* Medium screens */
@media (min-width: 600px) {
  .item-c {
    width: 50%; /* Show 2 items */
  }
}

/* Large screens */
@media (min-width: 1000px) {
  .item-c {
    width: 33.33%; /* Show 3 items */
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* This sets the height of the container to full viewport height */
}
