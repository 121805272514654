.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.slider-box {
  width: 290px;
  height: 370px;
  margin-bottom: 10px;
}
.btn {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin: 2px;
}
